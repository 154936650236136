<template>
  <el-dialog visible append-to-body :show-close="false" custom-class="task-snooze-modal" width="600px">
    <div></div>
    <template #title>
      <div class="d-flex justify-content-between align-items-start pt-4 pb-5">
        <div class="d-flex flex-column">
          <div class="title-text">{{ $t('tasks.singularTaskLayout.snoozeModal.title') }}</div>
          <slot name="subtile" />
        </div>
        <Button type="text" class="p-1 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon width="20" height="20" />
        </Button>
      </div>
    </template>

    <el-radio-group
      v-model="skipReason"
      class="d-flex mt-2"
      :class="showReasonError ? 'error-border' : ''"
      @change="clearReasonError"
    >
      <el-radio :label="SKIP_REASON_STATE.SNOOZE_TASK" class="m-0 pt-2 label-margin-8">
        <p class="d-inline-flex text-typography-primary mx-0">
          {{ $t('tasks.singularTaskLayout.snoozeModal.skipReason.snoozeTask') }}
        </p>
      </el-radio>
      <el-radio :label="SKIP_REASON_STATE.MANAGE_REVIEW_REQUIRED" class="pt-2 label-margin-8 space-between">
        <p class="d-inline-flex text-typography-primary mx-0">
          {{ $t('tasks.singularTaskLayout.snoozeModal.skipReason.managerReviewRequired') }}
        </p>
      </el-radio>
    </el-radio-group>
    <div v-if="skipReason === SKIP_REASON_STATE.SNOOZE_TASK">
      <div class="fw-bold pt-6">
        {{ $t('tasks.singularTaskLayout.snoozeModal.taskStallingDuration') }}
      </div>
      <el-radio-group
        v-model="stallingTime"
        class="d-flex flex-column mt-2"
        :class="showDelayDateError ? 'error-border' : ''"
        @change="clearDelayDateError"
      >
        <el-radio :label="STALING_TIME_RADIO_STATE.ONE_HOUR" class="m-0 pt-2">
          <p class="d-inline-flex text-typography-primary mx-2">
            {{ $t('tasks.singularTaskLayout.snoozeModal.stallingTime.oneHour') }}
          </p>
        </el-radio>
        <el-radio :label="STALING_TIME_RADIO_STATE.TWO_HOURS" class="m-0 pt-2">
          <p class="d-inline-flex text-typography-primary mx-2">
            {{ $t('tasks.singularTaskLayout.snoozeModal.stallingTime.twoHours') }}
          </p>
        </el-radio>
        <el-radio :label="STALING_TIME_RADIO_STATE.TOMORROW_AT_NINE" class="m-0 pt-2">
          <p class="d-inline-flex text-typography-primary mx-2">
            {{ $t('tasks.singularTaskLayout.snoozeModal.stallingTime.tomorrowAtNine') }}
          </p>
        </el-radio>
        <el-radio :label="STALING_TIME_RADIO_STATE.DAY_AFTER_TOMORROW_AT_NINE" class="m-0 pt-2">
          <p class="d-inline-flex text-typography-primary mx-2">
            {{ $t('tasks.singularTaskLayout.snoozeModal.stallingTime.dayAfterTomorrowAtNine') }}
          </p>
        </el-radio>
        <el-radio :label="STALING_TIME_RADIO_STATE.NEXT_WEEK_AT_NINE" class="m-0 pt-2">
          <p class="d-inline-flex text-typography-primary mx-2">
            {{ $t('tasks.singularTaskLayout.snoozeModal.stallingTime.nextWeekAtNine') }}
          </p>
        </el-radio>
        <el-radio :label="STALING_TIME_RADIO_STATE.PERSONALIZED" class="m-0 pt-2">
          <p class="d-inline-flex text-typography-primary mx-2">
            {{ $t('tasks.singularTaskLayout.snoozeModal.stallingTime.personalized') }}
          </p>
        </el-radio>
      </el-radio-group>
      <div v-if="stallingTime === STALING_TIME_RADIO_STATE.PERSONALIZED">
        <div class="fw-bold mt-3">
          {{ $t('commons.date') }}
        </div>
        <el-date-picker
          v-model="customDate"
          class="mt-1 mx-n2"
          format="dd.MM.yyyy"
          :picker-options="getDatePickerOptions()"
          :class="showDelayDatePersonalizedError ? 'error-border' : ''"
          @change="clearDelayTimePersonalizedError"
        />
        <div class="fw-bold mt-3">
          {{ $t('commons.hour') }}
        </div>
        <el-select
          v-model="customTime"
          class="mt-1"
          :popper-append-to-body="false"
          :placeholder="$t('commons.searchWithDots')"
        >
          <el-option v-for="time in timeArr" :key="time" :label="time" :value="time" suffix-icon />
        </el-select>
      </div>
    </div>
    <div v-else-if="skipReason === SKIP_REASON_STATE.MANAGE_REVIEW_REQUIRED" class="d-flex my-6">
      <FlagFullIcon color="#E47E03" />
      <div class="mx-2">{{ $t('tasks.singularTaskLayout.snoozeModal.skipReason.managerReviewRequired') }}</div>
    </div>
    <div v-if="!!skipReason">
      <div class="fw-bold mt-6">
        {{ $t('tasks.singularTaskLayout.snoozeModal.comments') }}
      </div>
      <el-input
        v-model="comment"
        :class="`comment-area mt-2 ${showCommentMandatoryError ? 'error' : ''}`"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 10 }"
        @input="clearCommentError"
      />
      <div v-show="showCommentMandatoryError" class="mandatory-field-error">
        {{ $t('tasks.singularTaskLayout.snoozeModal.mandatoryFieldError') }}
      </div>
    </div>
    <div class="d-flex justify-content-end mt-6">
      <Button class="cancel-button mx-2" type="secondary" :disabled="showLoader" @click="$emit('close')">{{
        $t('commons.cancel')
      }}</Button>
      <Button :class="`save-button mx-0`" type="primary" :loading="showLoader" @click="handleConfirm">
        {{ $t('commons.save') }}
      </Button>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { DateTime } from 'luxon';
import { isNil } from 'ramda';
import moment from 'moment';

import { CloseIcon, FlagFullIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

const STALING_TIME_RADIO_STATE = {
  ONE_HOUR: 'oneHour',
  TWO_HOURS: 'twoHours',
  TOMORROW_AT_NINE: 'tomorrowAtNine',
  DAY_AFTER_TOMORROW_AT_NINE: 'dayAfterTomorrowAtNine',
  NEXT_WEEK_AT_NINE: 'nextWeekAtNine',
  PERSONALIZED: 'personalized',
};

const SKIP_REASON_STATE = {
  SNOOZE_TASK: 'snoozeTask',
  MANAGE_REVIEW_REQUIRED: 'managerReviewRequired',
};

const timeArr = [
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export default {
  components: {
    CloseIcon,
    FlagFullIcon,
    Button,
  },
  props: {
    task: { type: Object, required: true },
  },
  emits: ['close', 'snooze-task'],
  setup(props) {
    return {
      STALING_TIME_RADIO_STATE,
      SKIP_REASON_STATE,
      stallingTime: ref(null),
      skipReason: ref(null),
      comment: ref(props.task.data?.comment ?? ''),
      showCommentMandatoryError: ref(false),
      showReasonError: ref(false),
      showDelayDateError: ref(false),
      showDelayDatePersonalizedError: ref(false),
      customDate: ref(new Date()),
      customTime: ref(timeArr[3]),
      timeArr,
      showLoader: ref(false),
    };
  },
  computed: {
    isTaskOverdue() {
      return DateTime.fromMillis(this.task.activeAt) <= DateTime.fromJSDate(new Date());
    },
    isTaskSnoozedByUser() {
      return !isNil(this.task.activeBy);
    },
  },
  methods: {
    clearCommentError(input) {
      this.showCommentMandatoryError = input ? false : this.showCommentMandatoryError;
    },
    clearReasonError() {
      this.showReasonError = false;
    },
    clearDelayDateError() {
      this.showDelayDateError = false;
    },

    clearDelayTimePersonalizedError(input) {
      if (input) {
        this.showDelayDatePersonalizedError = false;
      }
    },
    getDatePickerOptions() {
      return {
        disabledDate: (date) => date < DateTime.local().startOf('day').toJSDate(),
      };
    },
    updateErrors() {
      this.showReasonError = !this.skipReason;
      this.showDelayDateError = this.skipReason === SKIP_REASON_STATE.SNOOZE_TASK && !this.stallingTime;
      this.showDelayDatePersonalizedError =
        this.skipReason === SKIP_REASON_STATE.SNOOZE_TASK &&
        this.stallingTime === STALING_TIME_RADIO_STATE.PERSONALIZED &&
        !this.customDate;
      this.showCommentMandatoryError = this.skipReason && !this.comment;
    },
    handleConfirm() {
      this.updateErrors();
      const hasError =
        this.showReasonError ||
        this.showDelayDateError ||
        this.showCommentMandatoryError ||
        this.showDelayDatePersonalizedError;
      if (!hasError) {
        const stallingDate = this.getStallingDate();
        this.$emit('snooze-task', {
          activeAt: stallingDate,
          comment: this.comment,
          reviewRequired:
            this.skipReason === SKIP_REASON_STATE.MANAGE_REVIEW_REQUIRED ? true : this.task.reviewRequired,
        });
        this.showLoader = true;
      }
    },
    getStallingDate() {
      const nowTime = DateTime.fromJSDate(new Date());

      switch (this.stallingTime) {
        case STALING_TIME_RADIO_STATE.ONE_HOUR:
          return nowTime.plus({ hours: 1 }).toISO();
        case STALING_TIME_RADIO_STATE.TWO_HOURS:
          return nowTime.plus({ hours: 2 }).toISO();
        case STALING_TIME_RADIO_STATE.TOMORROW_AT_NINE:
          return nowTime.plus({ days: 1 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.DAY_AFTER_TOMORROW_AT_NINE:
          return nowTime.plus({ days: 2 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.NEXT_WEEK_AT_NINE:
          return nowTime.plus({ weeks: 1 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.PERSONALIZED:
          return DateTime.fromJSDate(this.customDate)
            .set({ hours: this.customTime.split(':')[0] })
            .set({ minutes: this.customTime.split(':')[1] })
            .toISO();

        default:
          return null;
      }
    },
    getTaskActivatedText() {
      let prefix = '';
      if (isNil(this.task.activeBy)) {
        if (this.isTaskOverdue) {
          prefix = this.$t('tasks.singularTaskLayout.snoozeModal.taskWasActivated');
        } else {
          prefix = this.$t('tasks.singularTaskLayout.snoozeModal.taskWillBeActivated');
        }
      } else {
        if (this.isTaskOverdue) {
          prefix = this.$t('tasks.singularTaskLayout.snoozeModal.taskWasActivatedByUser', {
            name: `${this.task.activeBy.firstName} ${this.task.activeBy.lastName}`,
          });
        } else {
          prefix = this.$t('tasks.singularTaskLayout.snoozeModal.taskWillBeActivatedByUser', {
            name: `${this.task.activeBy.firstName} ${this.task.activeBy.lastName}`,
          });
        }
      }

      return prefix;
    },
    getTaskActivatedTime() {
      return `${moment(this.task.activeAt).from(new Date())}, ${new Date(this.task.activeAt).toLocaleDateString(
        this.$i18n.locale,
        {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }
      )}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

::v-deep .task-snooze-modal {
  width: 456px;
  padding: 0px;
  border-radius: 8px;

  .el-dialog {
    &__body {
      color: $typography-primary;
      padding: 0px 16px 16px;
      margin: 0px;
    }

    &__header {
      padding: 0px 16px;
    }
  }

  input.el-input__inner {
    width: 212px;
  }

  textarea {
    resize: none;
  }
}

.title-text {
  font-size: 24px;
  color: #000000;
  font-weight: $font-weight-bold;
  padding: 0px;
}

.mandatory-field-error {
  color: $error;
}
::v-deep .comment-area {
  &.error {
    .el-textarea__inner {
      border-color: $error;
    }
  }
}

.save-button {
  &.disabled {
    background-color: #f3f3f4;
    color: #94989f;
    border: none;
  }
}

.cancel-button {
  background-color: #f2f3f5;
  border: none;
}

::v-deep .error-border {
  .el-radio__inner {
    border-color: $error;
  }
  .el-input__inner {
    border-color: $error;
  }

  .el-date-editor {
    border-color: $error;
  }
}

::v-deep .label-margin-8 {
  .el-radio__label {
    padding-right: 8px;
    padding-left: 8px;
  }
}

::v-deep .space-between {
  margin: 0 8px !important;
}
</style>
