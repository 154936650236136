<template>
  <div>
    <div v-if="!isLoadingTask" class="d-flex">
      <slot name="pagination"></slot>
      <Alerts
        v-if="errorsList.length"
        ref="alertsRef"
        key="errors"
        type="errors"
        :alerts-list="errorsList"
        :has-ok-button="false"
        :tooltip-message="errorsTooltipMessage"
      />
      <Alerts
        v-else-if="warningsList.length"
        ref="alertsRef"
        key="warnings"
        type="warnings"
        :alerts-list="warningsList"
        :tooltip-message="warningsTooltipMessage"
        :should-disable-ignore-warnings="shouldDisableIgnoreWarnings"
        @on-mark-as-ok="onMarkWarningsOk"
      />
      <RequestsButtonContainer
        v-if="task && task.businessId"
        class="my-2 mx-1"
        :source-business-id="task.businessId"
        :task-id="task.id"
        :target-businesses="requestsTargetBusinessesIds || [task.businessId]"
        :templates="requestTemplates"
        @template-selected="$emit('template-selected', $event)"
      />

      <Button class="my-2 mx-1 skip-button" type="secondary" :disabled="isActionsDisabled" @click="skipTask">
        <span>
          <span>
            {{ $t('tasks.singularTaskLayout.skipButton') }}
          </span>
        </span>
      </Button>

      <el-tooltip
        :content="$t('tasks.singularTaskLayout.removeManagerFlagToCompleteTask')"
        :disabled="!task?.reviewRequired"
        placement="top"
        popper-class="nav-bar-complete-tooltip"
      >
        <Button
          :disabled="!task?.id || task?.reviewRequired || isActionsDisabled"
          :loading="loadingAlertsList"
          class="my-2 mx-1 complete-button"
          @click="completeAndNextTask"
        >
          {{ $t('tasks.singularTaskLayout.completeButton') }}
        </Button>
      </el-tooltip>

      <el-dropdown
        class="d-flex"
        trigger="click"
        :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
        :disabled="isActionsDisabled"
        @command="handleAction"
      >
        <Button
          type="secondary"
          class="my-2 mx-0 align-self-center kebab-button"
          :class="$direction === 'rtl' ? 'me-1' : 'ms-1'"
        >
          <KebabIcon />
        </Button>
        <el-dropdown-menu slot="dropdown" class="el-dropdown-menu">
          <el-dropdown-item command="toggleManagerReviewRequired">
            <div class="d-flex align-items-center gap-2 text-typography-primary">
              <FlagIcon v-if="!task?.reviewRequired" />
              <FlagCrossIcon v-if="task?.reviewRequired" />
              <p>
                {{
                  task?.reviewRequired
                    ? $t('tasks.singularTaskLayout.menu.removeReviewRequired')
                    : $t('tasks.singularTaskLayout.menu.managerReviewRequired')
                }}
              </p>
            </div>
          </el-dropdown-item>
          <slot name="menu-dropdown-items"></slot>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <span class="placeholder bg-secondary mx-2 skeleton-size-1"></span>
        <span class="placeholder bg-secondary mx-2 skeleton-size-2"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { KebabIcon, FlagIcon, FlagCrossIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { ref, watch, computed, toRef } from 'vue';
import Alerts from './Alerts.vue';
import { useNotification } from '@/modules/core';

import RequestsButtonContainer from '@/modules/tasks/task/components/RequestsButtonContainer';

export default {
  components: {
    Button,
    KebabIcon,
    FlagIcon,
    FlagCrossIcon,
    Alerts,
    RequestsButtonContainer,
  },
  props: {
    task: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    requestsTargetBusinessesIds: {
      type: Array,
      required: false,
      default() {
        return null;
      },
    },
    requestTemplates: { type: Array, required: false, default: () => [] },

    isLoadingTask: {
      type: Boolean,
      required: true,
    },
    warningsTooltipMessage: {
      type: String,
      required: false,
      default: '',
    },
    errorsTooltipMessage: {
      type: String,
      required: false,
      default: '',
    },
    shouldDisableIgnoreWarnings: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    validateTaskBeforeCompleteHook: {
      type: Function,
      required: true,
      default: async () => {
        return {
          errors: [],
          warnings: [],
        };
      },
    },
    isActionsDisabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: ['complete-task', 'skip-task', 'is-manager-review-required'],

  setup(props, { emit }) {
    const { error } = useNotification();
    const warningsList = ref([]);
    const errorsList = ref([]);
    const loadingAlertsList = ref(false);
    const task = toRef(props, 'task');
    const taskId = computed(() => task.value.id);
    const alertsRef = ref(null);

    watch(task, (newTask, oldTask) => {
      if (newTask?.id !== oldTask?.id) {
        warningsList.value = [];
        errorsList.value = [];
        loadingAlertsList.value = false;
      }
    });

    const completeAndNextTask = async () => {
      loadingAlertsList.value = true;
      const _taskId = taskId.value;
      const {
        errors,
        warnings,
        shouldAbortCompletion = false,
      } = await props.validateTaskBeforeCompleteHook().catch((e) => {
        loadingAlertsList.value = false;
        error(e);
        throw e;
      });

      if (_taskId !== taskId.value) {
        return;
      }

      loadingAlertsList.value = false;
      errorsList.value = errors;
      warningsList.value = warnings;

      alertsRef?.value?.onClickAlertsOpen();

      if (shouldAbortCompletion) return;

      if (!errors.length && !warnings.length) {
        emit('complete-task');
      }
    };

    const skipTask = (value) => {
      emit('skip-task', value);
    };

    const handleAction = (command) => {
      if (command === 'toggleManagerReviewRequired') {
        const isManagerReviewRequired = !props.task.reviewRequired;
        emit('is-manager-review-required', isManagerReviewRequired);
      }
    };

    return {
      loadingAlertsList,
      handleAction,
      completeAndNextTask,
      skipTask,
      alertsRef,
      onMarkWarningsOk: () => {
        warningsList.value = false;
        emit('complete-task');
      },
      warningsList,
      errorsList,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .complete-button {
  position: relative;
  &.is-disabled {
    color: $typography-secondary;
    background-color: #f3f3f4;
  }

  height: 40px;
}

::v-deep .skip-button {
  position: relative;
  height: 40px;
}

.el-dropdown-menu {
  margin-top: -1px;
}

.kebab-button {
  padding: 8px;
}

.skeleton-size-1 {
  width: 200px;
  height: 40px;
}

.skeleton-size-2 {
  width: 40px;
  height: 40px;
}
</style>
<style lang="scss">
.nav-bar-complete-tooltip {
  width: 10rem !important;
  padding: 0.4rem !important;
  text-align: center !important;
}
</style>
