<template>
  <el-popover
    v-if="type === TYPES.errors && alertsList.length"
    class="d-flex justify-content-center align-items-center error-icon-color popover-modal"
    :class="showAlertsPopover ? 'active' : ''"
    placement="bottom"
    trigger="manual"
    :value="showAlertsPopover"
  >
    <div>
      <div class="d-flex m-3">
        <div class="d-flex align-items-center text-typography-primary full-width">
          <strong>{{ alertsTitle || $t('tasks.singularTaskLayout.errorsTitle') }}</strong>
        </div>
        <div class="m-1"></div>
        <div class="pointer closeIcon" @click="onClickAlertsClose">
          <CloseIcon width="20" height="20" />
        </div>
      </div>
      <hr class="hr-full-width" />
      <div class="d-flex flex-column align-content-start pb-1 text-typography-primary">
        <ul class="error-box">
          <li v-for="field in alertsList" :key="field">{{ field }}</li>
        </ul>
      </div>
    </div>
    <span slot="reference" class="alerts-container" @click="onClickAlertsOpen">
      <el-tooltip :content="tooltipMessage || $t('tasks.singularTaskLayout.errorsTooltip')" placement="top">
        <WarningFullIcon class="icon" />
      </el-tooltip>
    </span>
  </el-popover>
  <el-popover
    v-else-if="type === TYPES.warnings && alertsList.length"
    class="d-flex justify-content-center align-items-center warning-icon-color popover-modal"
    :class="showAlertsPopover ? 'active' : ''"
    placement="bottom"
    trigger="manual"
    :value="showAlertsPopover"
  >
    <div>
      <div class="d-flex m-3">
        <div class="d-flex align-items-center text-typography-primary full-width">
          <strong>{{ alertsTitle || $t('tasks.singularTaskLayout.warningsTitle') }}</strong>
        </div>
        <div class="m-1"></div>
        <div class="pointer closeIcon" @click="onClickAlertsClose">
          <CloseIcon width="20" height="20" />
        </div>
      </div>
      <hr class="hr-full-width" />
      <div class="d-flex flex-column align-content-start m-3 text-typography-primary">
        <ol class="order-list">
          <li v-for="field in alertsList" :key="field">{{ field }}</li>
        </ol>
      </div>
      <div v-if="hasOkButton" class="d-flex align-items-center m-3">
        <el-tooltip
          :disabled="!shouldDisableIgnoreWarnings"
          :content="tooltipMessage || $t('tasks.singularTaskLayout.pressCheckAndCompleteToContinue')"
          placement="top"
        >
          <span>
            <Button class="button-height" :disabled="shouldDisableIgnoreWarnings" @click="onOkButton">
              {{ $t('tasks.singularTaskLayout.markAsOkAndMoveToNextTask') }}
            </Button>
          </span>
        </el-tooltip>
      </div>
    </div>
    <span slot="reference" class="alerts-container" @click="onClickAlertsOpen">
      <el-tooltip :content="tooltipMessage || $t('tasks.singularTaskLayout.warningsTooltip')" placement="top">
        <WarningFullIcon class="icon" />
      </el-tooltip>
    </span>
  </el-popover>
  <div v-else></div>
</template>
<script>
import { WarningFullIcon, CloseIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { ref } from 'vue';

const TYPES = {
  errors: 'errors',
  warnings: 'warnings',
};
export default {
  name: 'Alerts',
  components: {
    WarningFullIcon,
    CloseIcon,
    Button,
  },
  props: {
    alertsList: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      required: false,
      validator: (value) => Object.values(TYPES).includes(value),
      default() {
        return TYPES.errors;
      },
    },
    alertsTitle: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    tooltipMessage: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    hasOkButton: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    shouldDisableIgnoreWarnings: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: ['on-mark-as-ok'],

  setup(_, { emit }) {
    const showAlertsPopover = ref(false);
    const onClickAlertsClose = () => {
      showAlertsPopover.value = false;
    };

    return {
      showAlertsPopover,
      onClickAlertsOpen: () => {
        showAlertsPopover.value = true;
      },
      onOkButton: () => {
        emit('on-mark-as-ok');
        onClickAlertsClose();
      },
      onClickAlertsClose,
      TYPES,
    };
  },
  mounted() {
    this.showAlertsPopover = true;
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.warning-icon-color {
  color: #e47e03;
  cursor: pointer;
  :hover {
    color: #b85c00;
  }

  &.active {
    color: #b85c00;
  }
}

.error-icon-color {
  color: $error;
  cursor: pointer;
  :hover {
    color: #bb0021;
  }
  &.active {
    color: #bb0021;
  }
}

.alerts-container {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */

  .icon {
    width: 20px;
    height: 20px;
  }
}

.error-box {
  color: $error;
  font-size: $font-size-medium;
  padding-inline-end: 40px;
  padding-inline-start: 40px;
}

.pointer {
  cursor: pointer;
}

.closeIcon {
  color: $typography-secondary;
}
.full-width {
  width: 100%;
}

.hr-full-width {
  margin: 12px 0;
}

.order-list {
  list-style-position: inside;
  padding: 0px;
}

.button-height {
  line-height: 20px;
}
</style>

<style lang="scss">
// the class hr-full-width used since the style here is not scoped and we don't want to change other popover that maybe in the page
.el-popover:has(hr.hr-full-width) {
  padding: 0;
}
</style>
