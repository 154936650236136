import { render, staticRenderFns } from "./SingularTaskNavBarActions.vue?vue&type=template&id=147f66c0&scoped=true"
import script from "./SingularTaskNavBarActions.vue?vue&type=script&lang=js"
export * from "./SingularTaskNavBarActions.vue?vue&type=script&lang=js"
import style0 from "./SingularTaskNavBarActions.vue?vue&type=style&index=0&id=147f66c0&prod&lang=scss&scoped=true"
import style1 from "./SingularTaskNavBarActions.vue?vue&type=style&index=1&id=147f66c0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147f66c0",
  null
  
)

export default component.exports