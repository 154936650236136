<template>
  <el-dialog visible top="33vh" custom-class="rounded" :show-close="false" width="600px">
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-0">
        <p class="modal-title pb-1">{{ $t('tasks.singularTaskLayout.commentModal.title') }}</p>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon class="m-1" width="20" height="20" />
        </Button>
      </div>
    </template>

    <el-input v-model="model.comments" :disabled="showLoader" type="textarea" class="pb-1" />

    <div slot="footer">
      <Button size="medium" :disabled="showLoader" type="secondary" @click="$emit('cancel')">
        {{ $t('tasks.singularTaskLayout.commentModal.cancel') }}
      </Button>

      <Button size="medium" :loading="showLoader" @click="handleSubmittedComment">
        {{ $t('tasks.singularTaskLayout.commentModal.save') }}
      </Button>
    </div>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

export default {
  components: { Button, CloseIcon },
  props: {
    comment: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['close', 'cancel', 'commentSubmitted'],
  data() {
    return {
      showLoader: false,
      model: {
        comments: this.comment || '',
      },
    };
  },
  methods: {
    handleSubmittedComment() {
      this.$emit('commentSubmitted', this.model.comments);
      this.showLoader = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.bold-radio {
  font-weight: bold;
}

.file-preview {
  width: 100%;
  border: 1px solid #d9dcde;
  border-radius: 4px;
  background-color: #fbfbfb;
  color: #131416;
}
::v-deep .el-dialog__body {
  padding: 20px 20px;
}
.modal-title {
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: #131416;
}

::v-deep textarea {
  resize: none;
  height: 67px;
}
</style>
